import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`20-OHS (95/65)`}</p>
    <p>{`200ft Shuttle Run (4x50ft sprints)`}</p>
    <p>{`20-Power Snatch (95/65)`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p>{`20-Burpees Over Bar`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p>{`10-Burpees Over Bar`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p>{`10-Power Snatch`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p>{`10-OHS`}</p>
    <p>{`200ft Shuttle Run.`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      